import { StaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'
import React from 'react'

export default ({ description, title, noScroll }) => (
  <StaticQuery
    query={graphql`
      query {
        seo: wordpressJoshuaSite {
          name
          description
        }
      }
    `}
    render={data => {
      const metaDescription = description || data.seo.description

      return (
        <Helmet
          htmlAttributes={{
            lang: 'nl',
            class: noScroll ? 'no-scroll' : '',
          }}
          title={title}
          titleTemplate={`%s | ${data.seo.name}`}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: 'Josh & Hope',
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ]}
        />
      )
    }}
  />
)
