import React from 'react'
import { css } from '@emotion/core'
import { minWidth } from '../breakpoints'
import { wrapperPadding } from '../variables'

export default ({ children, id, styles }) => (
  <div
    css={[
      css`
        margin: 0 auto;
        max-width: 1280px;
        padding-left: ${wrapperPadding.small}px;
        padding-right: ${wrapperPadding.small}px;

        ${minWidth.medium} {
          padding-left: ${wrapperPadding.medium}px;
          padding-right: ${wrapperPadding.medium}px;
        }
      `,
      styles,
    ]}
    id={id}
  >
    {children}
  </div>
)
