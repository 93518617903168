import { Link, StaticQuery, graphql } from 'gatsby'
import { maxWidth, minWidth } from '../breakpoints'

import React from 'react'
import Socials from './socials'
import { colors } from '../variables'
import font from '../fonts'

export default ({ open }) => (
  <StaticQuery
    query={graphql`
      query {
        menu: allWordpressJoshuaMenu {
          items: edges {
            item: node {
              id
              label
              link
            }
          }
        }
      }
    `}
    render={({ menu: { items } }) => (
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          [maxWidth.navigation]: {
            background: colors.blue30,
            borderTop: `6px solid ${colors.blue60}`,
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            left: 0,
            opacity: open ? 1 : 0,
            overflowX: 'hidden',
            overflowY: 'scroll',
            padding: '4rem 0',
            pointerEvents: open ? 'all' : 'none',
            position: 'fixed',
            top: 0,
            transition: 'opacity .3s cubic-bezier(0, 0, 0, 1)',
            width: '100%',
            zIndex: 2,
          },
          [minWidth.navigation]: {
            flexGrow: 1,
            justifyContent: 'flex-end',
          },
        }}
      >
        <div
          css={{
            [maxWidth.navigation]: {
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 30,
              padding: '0 20px',
              textAlign: 'center',
              width: '100%',
              '> .navigation-link--active': {
                color: colors.orange,
              },
            },
            [minWidth.navigation]: {
              '> .navigation-link--active': {
                color: colors.blue60,
              },
            },
          }}
        >
          {items.map(({ item }) => (
            <Link
              activeClassName="navigation-link--active"
              css={{
                fontFamily: font('Raleway'),
                textDecoration: 'none',
                [maxWidth.navigation]: {
                  color: 'white',
                  fontSize: '1.5rem',
                  fontWeight: 800,
                  transition: 'transform .2s cubic-bezier(0, 0, 0, 1)',
                  ':hover': {
                    transform: 'scale(1.1)',
                  },
                },
                [minWidth.navigation]: {
                  color: 'black',
                  marginRight: 20,
                  transition: 'color .3s ease-out',
                  ':hover': {
                    color: colors.blue60,
                  },
                },
                [minWidth.full]: {
                  marginRight: 35,
                },
              }}
              key={item.id}
              to={item.link}
            >
              {item.label}
            </Link>
          ))}
        </div>

        <Socials />
      </div>
    )}
  />
)
