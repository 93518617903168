import React from 'react'
import { colors } from '../variables'
import { minWidth } from '../breakpoints'

export default class MenuIcon extends React.Component {
  state = {
    open: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      this.props.onChange(this.state.open)
    }
  }

  render() {
    return (
      <div
        css={{
          alignItems: 'center',
          background: colors.orange,
          borderRadius: '100%',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          height: 40,
          width: 40,
          [minWidth.navigation]: {
            display: 'none',
          },
        }}
        onClick={() => this.setState({ open: !this.state.open })}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            height: 12,
            justifyContent: 'space-between',
          }}
        >
          <Line
            styles={
              this.state.open
                ? {
                    transform:
                      'rotate(-45deg) translateX(-1.5px) translateY(-1.5px)',
                  }
                : {}
            }
          />
          <Line
            styles={[
              { transformOrigin: '50% 50%' },
              this.state.open ? { transform: 'scaleX(0)' } : {},
            ]}
          />
          <Line
            styles={
              this.state.open
                ? {
                    transform:
                      'rotate(45deg) translateX(-1.5px) translateY(1.5px)',
                  }
                : {}
            }
          />
        </div>
      </div>
    )
  }
}

const Line = ({ styles }) => (
  <div
    css={[
      {
        background: 'white',
        height: 2,
        transformOrigin: '100% 50%',
        transition: 'transform .3s cubic-bezier(0, 0, 0, 1)',
        width: 14,
      },
      styles,
    ]}
  />
)
