export const colors = {
  blue30: '#00264D',
  blue60: '#004D99',
  blue80: '#E6F5FF',
  blue90: '#F2FAFF',
  greyBlue: '#5C8099',
  orange: '#FF6600',
}
export const transition = '0.4s cubic-bezier(0, 0, 0, 1)'
export const wrapperPadding = {
  small: 20,
  medium: 40,
}
