import React from 'react'
import { colors } from '../variables'
import { css } from '@emotion/core'
import font from '../fonts'
import { minWidth } from '../breakpoints'

export default ({ children, position = 'top', styles, moreSpace = false }) => (
  <h4
    css={[
      css`
        color: ${colors.blue60};
        font-family: ${font('Raleway')};
        font-weight: 800;
        margin-bottom: ${position === 'top' ? '0.75em' : '0'};
        text-transform: uppercase;

        ${minWidth.tablet} {
          margin-bottom: ${position === 'top'
            ? moreSpace
              ? '1.1em'
              : '0.55em'
            : '0'};
        }
      `,
      styles,
    ]}
  >
    {children}
  </h4>
)
