import React from 'react'
import { colors } from '../variables'
import { css } from '@emotion/core'

const Social = ({ children, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    css={css`
      align-items: center;
      background: ${colors.orange};
      border-radius: 50%;
      display: flex;
      height: 40px;
      transition: background-color 0.2s ease-out;
      justify-content: center;
      width: 40px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:hover {
        background: ${colors.blue60};
      }

      svg {
        display: block;
      }
    `}
  >
    {children}
  </a>
)

export default () => (
  <div
    css={css`
      display: flex;
    `}
  >
    <Social url="https://nl.linkedin.com/in/joshuavaneijndhoven/">
      <svg
        height="15.94"
        viewBox="0 0 16 15.94"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m3.581 15.9360081h-3.317v-10.68000003h3.317zm-1.66-12.13600003c-1.06 0-1.921-.88-1.921-1.94.0334588-1.03681811.88364217-1.86000807 1.921-1.86000807s1.8875412.82318996 1.921 1.86000807c0 1.06-.86 1.939-1.921 1.939zm14.075 12.13600003h-3.31v-5.198c0-1.24000003-.025-2.82800003-1.724-2.82800003-1.725 0-1.989 1.346-1.989 2.73800003v5.288h-3.314v-10.68000003h3.182v1.457h.046c.443-.839 1.525-1.724 3.139-1.724 3.356 0 3.974 2.21 3.974 5.08000003v5.867z"
          fill="#fff"
        />
      </svg>
    </Social>
    <Social url="https://twitter.com/joshandhope/">
      <svg
        height="13"
        viewBox="0 0 16 13"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m14.355 3.23900061c.01.142.01.284.01.426 0 4.335-3.299 9.32999999-9.33 9.32999999-1.78507757.0045154-3.53347078-.5066336-5.035-1.472.264.03.518.04.792.04 1.47541643.0037913 2.90912443-.4893761 4.07-1.4-1.40263235-.0260773-2.63391713-.93999995-3.065-2.27499999.203.03.406.051.62.051.294 0 .588-.04.862-.112-1.53033095-.30989816-2.63007238-1.65560686-2.629-3.217v-.04c.437.243.945.395 1.483.416-.91467091-.6079642-1.46396268-1.63370932-1.463-2.732 0-.61.162-1.168.447-1.655 1.66572116 2.04998004 4.12299296 3.29697007 6.761 3.431-.05246742-.24658973-.0796083-.49789409-.081-.75-.00053102-.87007277.34486889-1.70466217.96010336-2.31989664s1.44982384-.96063438 2.31989664-.96010336c.943 0 1.796.396 2.395 1.036.7343008-.14148506 1.4384377-.40946988 2.081-.792-.2443599.75765991-.7567209 1.40015557-1.441 1.807.66-.071 1.3-.254 1.888-.508-.4490076.65385798-1.0051516 1.22724413-1.645 1.696z"
          fill="#fff"
        />
      </svg>
    </Social>
    <Social url="https://www.facebook.com/Joshandhopesailingtheworld/">
      <svg
        height="16"
        viewBox="0 0 8.25 16"
        width="8.25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m2.397 16v-7.156h-2.397v-2.844h2.397v-2.24c0-2.435 1.487-3.76 3.66-3.76 1.04 0 1.934.078 2.193.113v2.543h-1.506c-1.181 0-1.41.563-1.41 1.385v1.959h2.666l-.366 2.844h-2.3v7.156"
          fill="#fff"
        />
      </svg>
    </Social>
  </div>
)
