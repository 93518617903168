import React from 'react'
import Socials from './socials'
import Wrapper from './wrapper'
import { colors } from '../variables'
import { css } from '@emotion/core'
import font from '../fonts'
import { minWidth } from '../breakpoints'

export default () => (
  <div
    css={css`
      background: ${colors.blue30};
      color: white;
      font-family: ${font('Raleway')};
      padding: 35px 0 30px;
      text-align: center;

      ${minWidth.medium} {
        padding: 20px 0;
        text-align: left;
      }
    `}
  >
    <Wrapper>
      <div
        css={css`
          align-items: center;
          display: flex;
          flex-direction: column;

          ${minWidth.medium} {
            flex-direction: row;
            justify-content: space-between;
          }
        `}
      >
        <strong
          css={css`
            display: block;
            font-size: 16px;
            font-weight: 700;
            margin-top: 1em;
            order: 1;

            ${minWidth.medium} {
              margin-top: 0;
            }
          `}
        >
          ©{' '}
          <span
            css={css`
              text-transform: lowercase;
            `}
          >
            Copyright
          </span>{' '}
          {new Date().getFullYear()} - Josh & Hope
        </strong>
        <Socials />
      </div>
    </Wrapper>
  </div>
)
