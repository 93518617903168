import { colors, transition } from '../variables'

import Anchor from './anchor'
import { Link } from 'gatsby'
import React from 'react'
import { css } from '@emotion/core'
import font from '../fonts'
import { minWidth } from '../breakpoints'

const baseStyles = css`
  align-items: center;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-family: ${font('Raleway')};
  font-weight: 700;
  justify-content: center;
  line-height: 1.2;
  outline: none;
  overflow: hidden;
  padding: 1em 1.7em;
  position: relative;
  text-align: right;
  text-decoration: none;

  ${minWidth.tablet} {
    padding-bottom: 1.1em;
    padding-top: 1.1em;
  }
`
const Arrow = ({ color, direction }) => (
  <div
    css={css`
      margin-left: 0.4em;
      transform: ${direction === 'down' ? 'rotate(90deg)' : 'rotate(0deg)'};
    `}
  >
    <svg
      css={css`
        display: block;
      `}
      fill={color}
      height="8.24"
      viewBox="0 0 14.2 8.24"
      width="14.2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m14.203125 4.12207031-4.1220703 4.12207031v-3.36621093h-10.0810547v-1.52050781h10.0810547l.0087891-3.35742188z" />
    </svg>
  </div>
)

export { baseStyles }
export default ({
  styles,
  to,
  children,
  color = 'lightBlue',
  direction = 'right',
  type,
}) => {
  const background =
    color === 'orange'
      ? colors.orange
      : color === 'blue'
      ? colors.blue60
      : colors.blue80
  const base = [baseStyles, css`
    &:hover .button-background {
      background: ${background === colors.orange
        ? colors.blue60
        : background === colors.blue60
        ? colors.orange
        : colors.blue80};
      transform: ${background === colors.blue80 ? 'translateX(-4em)' : 'none'};
    }

    &:hover .arrow {
      transform: translate(
        ${direction === 'down' ? '0' : '0.5em'},
        ${direction === 'down' ? '0.5em' : '0'}
      );
    }
  `]
  const childrenElement = (
    <div>
      <div
        className="button-background"
        css={css`
          background: ${background};
          height: 100%;
          left: 0;
          position: absolute;
          transition: transform ${transition}, background-color ${transition};
          top: 0;
          width: 100%;
        `}
      />
      <div
        css={css`
          color: ${color === 'orange' || color === 'blue'
            ? 'white'
            : colors.blue60};
          position: relative;
          transition: color ${transition};
        `}
      >
        {children}
      </div>
    </div>
  )
  const arrowElement = (
    <div
      className="arrow"
      css={css`
        transition: transform ${transition};
      `}
    >
      <Arrow
        color={
          color === 'orange' || color === 'blue' ? 'white' : colors.blue60
        }
        direction={direction}
      />
    </div>
  )

  if (to) {
    if (/^\/(?!\/)/.test(to)) {
      return (
        <Link css={[base, styles]} to={to}>
          {childrenElement}
          {arrowElement}
        </Link>
      )
    } else {
      return /^#/.test(to) ? (
        <Anchor target={to}>
          <div css={[base, styles]}>
            {childrenElement}
            {arrowElement}
          </div>
        </Anchor>
      ) : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          css={[base, styles]}
          href={to}
        >
          {childrenElement}
          {arrowElement}
        </a>
      )
    }
  } else {
    return type === 'submit' ? (
      <button css={[base, styles]} type="submit">
        {childrenElement}
        {arrowElement}
      </button>
    ) : (
      <div css={[base, styles]}>
        {childrenElement}
        {arrowElement}
      </div>
    )
  }
}
