import React from 'react'
import { Global, css } from '@emotion/core'

export default ({ children }) => (
  <>
    <Global
      styles={css`
        html {
          -ms-overflow-style: -ms-autohiding-scrollbar;
          -webkit-font-smoothing: antialiased;

          &.no-scroll,
          &.no-scroll body {
            height: 100%;
            overflow-y: hidden;
          }
        }

        .video-container {
          position: relative;
          padding-bottom: ${(9 / 16) * 100}%;

          embed,
          iframe,
          object {
            background: #ddd;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      `}
    />
    <div>{children}</div>
  </>
)
