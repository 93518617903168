import { StaticQuery, graphql } from 'gatsby'

import Button from './button'
import Img from 'gatsby-image'
import React from 'react'
import Supertitle from './supertitle'
import Wrapper from './wrapper'
import { colors } from '../variables'
import { css } from '@emotion/core'
import { minWidth } from '../breakpoints'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        book: file(relativePath: { eq: "book.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320, quality: 80) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        content: wordpressJoshuaSite {
          author {
            supertitle
            title
            text
            link
          }
        }
      }
    `}
    render={data => (
      <div
        css={css`
          background: ${colors.blue90};
          padding: 2.5em 0 4.25em;

          ${minWidth.medium} {
            padding-top: 4em;
          }

          ${minWidth.laptop} {
            padding: 5em 0 5.25em;
          }
        `}
      >
        <Wrapper>
          <div
            css={css`
              align-items: center;
              display: flex;
              flex-direction: column;

              ${minWidth.medium} {
                align-items: flex-start;
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                margin-bottom: 2em;
                max-width: 240px;
                width: 100%;

                ${minWidth.medium} {
                  margin-bottom: 0;
                  flex-shrink: 0;
                }

                ${minWidth.tablet} {
                  max-width: 300px;
                }

                ${minWidth.laptop} {
                  max-width: 360px;
                  margin-right: 2.25em;
                }
              `}
            >
              <Img
                alt="Op reis met de Hope"
                fluid={data.book.childImageSharp.fluid}
              />
            </div>
            <div
              css={css`
                ${minWidth.tablet} {
                  margin-top: 1.5em;
                }
              `}
            >
              <Supertitle>{data.content.author.supertitle}</Supertitle>
              <h2>{data.content.author.title}</h2>
              <p>{data.content.author.text}</p>
              <Button color="orange" to={data.content.author.link}>
                Meer over mijn boek
              </Button>
            </div>
          </div>
        </Wrapper>
      </div>
    )}
  />
)
