const breakpoints = {
  medium: 640,
  tablet: 768,
  laptop: 1024,
  navigation: 1100,
  full: 1280,
}

export { breakpoints }
export const minWidth = Object.keys(breakpoints).reduce((minWidth, name) => {
  minWidth[name] = `@media(min-width: ${breakpoints[name]}px)`

  return minWidth
}, {})
export const maxWidth = Object.keys(breakpoints).reduce((maxWidth, name) => {
  maxWidth[name] = `@media(max-width: ${breakpoints[name] - 1}px)`

  return maxWidth
}, {})
