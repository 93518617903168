import { Link } from 'gatsby'
import MenuIcon from './menu-icon'
import Navigation from './navigation'
import React from 'react'
import Wrapper from './wrapper'
import { colors } from '../variables'
import { minWidth } from '../breakpoints'

export default class Header extends React.Component {
  state = {
    menuOpen: false,
  }

  render() {
    return (
      <>
        <div
          css={{
            position: 'fixed',
            borderTop: `8px solid ${colors.blue60}`,
            boxShadow: `0 1px 5px rgba(0,0,0,0.25)`,
            marginBottom: 80,
            width: '100%',
            background: 'white',
            zIndex: 3,
          }}
        >
          <Wrapper>
            <div
              css={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0 15px 0',
                [minWidth.tablet]: {
                  marginBottom: 25,
                  marginTop: 25,
                },
              }}
            >
              <Link
                css={{
                  color: 'black',
                  fontSize: '1.125em',
                  fontWeight: 800,
                  lineHeight: '1em',
                  textDecoration: 'none',
                  [minWidth.tablet]: {
                    fontSize: '1.33em',
                  },
                }}
                to="/"
              >
                Josh
                <span css={{ color: colors.orange }}>&amp;</span>
                Hope
                <span css={{ color: colors.orange }}>&amp;</span>
                Katia
              </Link>
              <Navigation open={this.state.menuOpen} />
              <div css={{ zIndex: 2 }}>
                <MenuIcon
                  onChange={menuOpen =>
                    this.setState({ menuOpen }, () =>
                      this.props.onMenuChange(menuOpen)
                    )
                  }
                />
              </div>
            </div>
          </Wrapper>
        </div>
        <div
          css={{
            height: 80,
            width: '100%',
            [minWidth.tablet]: {
              height: 100,
            },
          }}
        />
      </>
    )
  }
}
