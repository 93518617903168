import React from 'react'
import { css } from '@emotion/core'

export default ({ children, styles }) => (
  <div
    css={[
      css`
        max-width: 840px;
        margin: 0 auto;
        width: 100%;
      `,
      styles,
    ]}
  >
    {children}
  </div>
)
